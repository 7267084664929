import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import {
  Flex,
  Box,
  Text,
  Divider,
  Image,
  Badge,
  Select,
} from "@chakra-ui/react";
import Nouislider from "nouislider-react";
import Car from "../components/home/game/car";
import YellowCar from "../assets/car/yellow.png";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import LeaderBoard from "../components/home/leader-board";
import { RiWhatsappFill } from "react-icons/ri";
import StorageService from "../service/storage";
import { STORAGE } from "../constants/";
import CongratulatoryModal from "../components/CongratulatoryModal";
import { BarChartComponent } from "./road-map";

const Home2 = () => {
  const [type, setType] = useState(StorageService.get(STORAGE.UNIT) || "wrp");
  const [target, setTarget] = useState(
    StorageService.get(STORAGE.TARGET) || ""
  );

  const onSelectChange = (val: string) => {
    if (val === "") {
      return;
    }

    setType(val);
    StorageService.set(STORAGE.UNIT, val);
  };

  const onTargetTypeSelected = (val: string) => {
    if (val === "") {
      return;
    }

    setTarget(val);
    StorageService.set(STORAGE.TARGET, val);
  };

  return (
    <Layout p={4}>
      <ShowCompletedCount />

      <Box p={4} pt={0} pr={0} textAlign="right">
        <Text fontSize="xs" color="gray.400">
          Select Criteria{" "}
        </Text>
        <Select
          placeholder="Select"
          colorScheme="gray"
          size="sm"
          maxWidth="80px"
          ml="auto"
          borderRadius="8px"
          textAlign="center"
          borderColor="gray.400"
          value={type}
          onChange={(e) => onSelectChange(e.target.value)}
        >
          <option value="wrp">WRP</option>
          <option value="fyc">FYC</option>
          <option value="fyc">FYP</option>
        </Select>
      </Box>

      <ProgressWithMilestone />

      <RaceTrack />

      <Divider color="gray.400" />

      <LeaderBoard />

      <br />
      <br />

      <BarChartComponent />

      <Text fontSize="sm">
        <>
          The graph to mention Issuance _____, shortfall to achieve_____,
          issuable_______ (run rate – leaderboard to achieve 29,38,300 premium
          goal.
          <br />
          Required _______ (premium gap monthly) eg 15 lakhs shortfall, 3 months
          balance – 5 lakhs required per month – with 1 lakh average ticket
          size, 5 NOPs required – min. (x) nos. of customers to be met.
        </>
      </Text>
      {/* 
      <Box p={4} textAlign="left">
        <Text mb={1} color="gray.400">
          Will you be able to achieve your target ?
        </Text>
        <Select
          colorScheme="gray"
          ml="auto"
          borderRadius="8px"
          textAlign="center"
          borderColor="gray.400"
          value={target}
          onChange={(e) => onTargetTypeSelected(e.target.value)}
          fontSize="sm"
        >
          <option value="complete">
            I will be able to complete my target in full.
          </option>
          <option value="full">
            I will be able to complete part of my target.
          </option>
        </Select>
      </Box> */}

      <CongratulatoryModal />

      <br />
      <br />
      <br />
    </Layout>
  );
};

export default Home2;

const ShowCompletedCount = () => {
  return (
    <Box
      bg="primary.500"
      borderWidth="1px"
      borderColor="gray.200"
      p={2}
      py={4}
      borderRadius="8px"
      mb={6}
      shadow="lg"
    >
      <Text
        fontSize="12px"
        fontFamily="heading"
        color="white"
        textAlign="center"
      >
        50 users have already qualified <b>MDRT</b>
      </Text>
    </Box>
  );
};

const ProgressWithMilestone = () => {
  const [value, setValue] = useState(17.38);

  return (
    <>
      <Flex direction="row" justify="space-between" mb={3}>
        <Box>
          <Text mb={1} fontFamily="heading" fontSize="xs">
            Target
          </Text>
          <Text fontFamily="heading" color="primary.500">
            &#8377; 29,38,300
          </Text>
        </Box>

        <Box textAlign="right">
          <Text mb={1} fontFamily="heading" fontSize="xs">
            Position
          </Text>
          <Text fontFamily="heading" color="green.500">
            200<sup>th</sup>
          </Text>
        </Box>
      </Flex>

      <Flex direction="row" justify="space-between" mb={6}>
        <Box>
          <Text mb={1} fontFamily="heading" fontSize="xs">
            You Have Achieved
          </Text>
          <Text fontFamily="heading" color="red.500">
            &#8377; 17,38,300
          </Text>
        </Box>

        <Box textAlign="right">
          <Text mb={1} fontFamily="heading" fontSize="xs">
            Next Milestone Target
          </Text>
          <Text fontFamily="heading" color="secondary.500">
            &#8377; 22,03,725
          </Text>
        </Box>
      </Flex>

      <Box py={8} px={4}>
        <Nouislider
          connect
          start={value}
          behaviour="tap"
          range={{
            min: 0,
            max: 29.38,
          }}
          pips={{ mode: "count", values: 5 }}
          tooltips={true}
          disabled={true}
          animate={true}
        />
      </Box>

      <Text mr={4} fontSize="10px" textAlign="right" mt={5} color="gray.400">
        * Above numbers are in Lakhs
      </Text>
    </>
  );
};

const RaceTrack = () => {
  return (
    <Box my={4}>
      <Box mx={-4} position="relative" mb={8}>
        <Image src="/images/plain-road.png" height="150px" width="100%" />

        <Flex direction="row" align="center" ml="75px" mt="-70px">
          <Box display="inline-block" transform="rotate(-90deg)">
            <Badge>200</Badge>
          </Box>

          <Box width="100px">
            <Image src={YellowCar} />
          </Box>
        </Flex>
      </Box>

      <Flex direction="row" justify="space-between" mb={4}>
        <Text fontFamily="heading" fontSize="xs" color="gray.400">
          To achieve your next milestone you will need minimum of
        </Text>
        <Box ml="auto">
          <Text
            ml="auto"
            minWidth="95px"
            fontFamily="heading"
            fontSize="sm"
            textAlign="right"
          >
            5 QRs/Week
          </Text>
        </Box>
      </Flex>

      <Flex direction="row" justify="space-between" mb={4}>
        <Text fontFamily="heading" fontSize="xs" color="gray.400">
          MY QR target per Week
        </Text>
        <Box>
          <Flex direction="row" align="center">
            <Box as={AiFillMinusCircle} fontSize="2xl" color="red.500" />
            <Text fontFamily="heading" mx={4}>
              5
            </Text>
            <Box as={AiFillPlusCircle} fontSize="2xl" color="green.500" />
          </Flex>
        </Box>
      </Flex>

      <Flex direction="row" justify="space-between" mb={4}>
        <Text fontFamily="heading" fontSize="xs" color="gray.400">
          You will become MDRT in
        </Text>
        <Box>
          <Flex direction="row" align="center">
            <Text fontFamily="heading" mx={4} fontSize="sm" textAlign="right">
              5 Weeks
            </Text>
          </Flex>
        </Box>
      </Flex>

      <Flex direction="row" justify="space-between" mb={4}>
        <Text fontFamily="heading" fontSize="xs" color="gray.400">
          Projected Position
        </Text>
        <Box>
          <Flex direction="row" align="center">
            <Text fontFamily="heading" mx={4} fontSize="sm" textAlign="right">
              200<sup>th</sup>
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
