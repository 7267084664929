import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
} from "@chakra-ui/react";
import Layout from "../components/layout";
import MDRT from "../assets/MDRT Banner.jpg";
import CEOCOUNCIL from "../assets/CEO Council Banner.jpg";
import storage from "../service/storage";
import { STORAGE } from "../constants";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import ReactCardFlip from "react-card-flip";

const SelectScreen = () => {
  const history = useHistory();
  const [flippedCard, setFilppedCard] = useState("");

  const goToNextStep = (value: string) => {
    storage.set(STORAGE.UNIT, value);
    history.push("/tabs/home");
  };

  return (
    <Layout p={4}>
      <Text fontSize="sm" mb={4} textAlign="center" color="gray.400">
        Please click on anyone of the below banners for more details
      </Text>

      <FlipImageBox
        img={CEOCOUNCIL}
        activeType={flippedCard}
        type={"mdrt"}
        onClickImage={(val) => setFilppedCard(val)}
        url="/tabs/home"
        dateRange="1st Jan 21 to 31st Dec 21"
        fyp="29,38,800"
        fypRunRate="56,515"
        fyc="7,34,700"
        fycRunRate="14,128"
      />

      <FlipImageBox
        img={MDRT}
        activeType={flippedCard}
        type={"ceo's Council"}
        onClickImage={(val) => setFilppedCard(val)}
        url="/tabs/home"
        dateRange="1st July 21 to 30th June 22"
        fyp="88,16,400"
        fypRunRate="169,546"
        fyc="22,04,100"
        fycRunRate="44,028"
      />

      {/* <motion.div
        animate={{
          x: ["-1000px", "0px"],
          opacity: ["0", "1"],
        }}
        transition={{
          type: "tween",
          delay: 0.5,
          duration: 1.5,
        }}
      >
        <ReactCardFlip isFlipped={flippedCard == "mdrt" ? true : false}>
          <Image
            src={CEOCOUNCIL}
            mb={4}
            boxShadow="0 3px 10px rgb(0 0 0 / 0.2)"
            borderRadius="8px"
            width="100%"
            onClick={() => {
              goToNextStep("mdrt");
            }}
          />
          <Box>Test Test</Box>
        </ReactCardFlip>
      </motion.div>

      <motion.div
        animate={{
          x: ["1000px", "0px"],
          opacity: ["0", "1"],
        }}
        transition={{
          type: "tween",
          delay: 1.5,
          duration: 1.5,
        }}
      >
        <Image
          src={MDRT}
          mb={4}
          boxShadow="0 3px 10px rgb(0 0 0 / 0.2)"
          borderRadius="8px"
          width="100%"
          onClick={() => {
            goToNextStep("wcp");
          }}
        />
      </motion.div> */}

      <Text fontSize="sm" textAlign="center" color="gray.400">
        Please click on anyone of the above banners for more details
      </Text>
    </Layout>
  );
};

export default SelectScreen;

interface IFlipImageBox {
  img: string | any;
  activeType: string;
  type: string;
  onClickImage: (type: string) => void;
  url: string;
  dateRange?: string;
  fyp?: string;
  fypRunRate?: string;
  fyc?: string;
  fycRunRate?: string;
}

const FlipImageBox = ({
  img,
  activeType,
  type,
  onClickImage,
  url,
  dateRange,
  fyp,
  fypRunRate,
  fyc,
  fycRunRate,
}: IFlipImageBox) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [boxHeight, setBoxHeight] = useState(315.8);

  const history = useHistory();

  const goToNextStep = () => {
    storage.set(STORAGE.UNIT, type);
    history.push("/tabs/home");
  };

  useEffect(() => {
    if (imageRef.current?.height) {
      setBoxHeight(imageRef.current?.height);
    }
  }, []);

  return (
    <Box mb={4} borderRadius="8px" overflow="hidden">
      <ReactCardFlip isFlipped={activeType == type ? true : false}>
        <Image
          src={img}
          width="100%"
          boxShadow="0 3px 10px rgb(0 0 0 / 0.2)"
          onClick={() => onClickImage(type)}
          ref={imageRef}
        />
        <Flex
          height={`${boxHeight}px`}
          width="100%"
          bg="gray.100"
          borderRadius="8px"
          p={6}
          direction="column"
          justify="space-between"
        >
          <Text
            color="secondary.500"
            fontSize="2xl"
            fontFamily="heading"
            letterSpacing="2px"
            textAlign="center"
            textTransform="uppercase"
            mb={0}
          >
            {type}
          </Text>

          <Table fontSize="sm" textAlign="center" my={2}>
            <Tbody>
              <Tr>
                <Td p={2}>Date: </Td>
                <Td p={2}> {dateRange} </Td>
              </Tr>
              <Tr>
                <Td p={2}>FYP Criteria: </Td>
                <Td p={2}>
                  <Box>
                    &#8377; {fyp} /-
                    <Text fontSize="xs" color="gray.500">
                      &#8377; {fypRunRate} /- Req. run rate per week
                    </Text>
                  </Box>
                </Td>
              </Tr>
              <Tr>
                <Td p={2}>FYC Criteria </Td>
                <Td p={2}>
                  <Box>
                    &#8377; {fyc} /-
                    <Text fontSize="xs" color="gray.500">
                      &#8377; {fycRunRate} /- Req. run rate per week
                    </Text>
                  </Box>
                </Td>
              </Tr>
            </Tbody>
          </Table>

          <Button
            fontWeight="300"
            fontFamily="heading"
            colorScheme="primary"
            fontSize="sm"
            onClick={goToNextStep}
          >
            Click Here For More Details
          </Button>
        </Flex>
      </ReactCardFlip>
    </Box>
  );
};
