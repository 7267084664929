import React from "react";
import { Flex, Image, Box, Link } from "@chakra-ui/react";
import logo from "../assets/logo.png";
import { RiWhatsappFill } from "react-icons/ri";

const Header = () => {
  const message =
    "Hi, I would like to discuss with you regarding, helping me achieve my success milestones. Thank you.";

  const number = "9819041195";

  return (
    <Box>
      <Flex
        align="center"
        justify="space-between"
        width="100vw"
        maxWidth="600px"
        mx="auto"
        p={2}
        bg="secondary.500"
      >
        <Image src={logo} alt="RNLIC Logo" height="50px" />

        <Link
          isExternal
          href={`https://wa.me/91${number}?text=${encodeURI(message)}`}
          _focus={{
            boxShadow: "none",
            outline: "0",
          }}
        >
          <Box fontSize="32px" as={RiWhatsappFill} color="whatsapp.600" />
        </Link>
      </Flex>
    </Box>
  );
};

export default Header;
