import React from "react";

import { ThemeProvider, CSSReset } from "@chakra-ui/react";
import theme from "./theme";
import Navigation from "./navigation";
import Header from "./components/header";

import "nouislider/distribute/nouislider.css";
import "./App.css";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Header />
      <Navigation />
    </ThemeProvider>
  );
}

export default App;
