import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import { Progress, Box, Image, Text, Button, Flex } from "@chakra-ui/react";
import logo from "../assets/logo.png";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

const LoadingScreen = () => {
  const TIME = 8000;
  const [value, setValue] = useState(0);
  const [showBtn, SetShowBtn] = useState(false);
  const history = useHistory();

  useEffect(() => {
    let loadingInterval: any;
    loadingInterval = setInterval(() => {
      if (value > 100) {
        SetShowBtn(true);
        clearInterval(loadingInterval);
      } else {
        setValue((value) => value + 1);
      }
    }, TIME / 100);

    let loadingTimer = setTimeout(() => {
      SetShowBtn(true);
    }, TIME);

    return () => {
      if (loadingInterval) {
        clearInterval(loadingInterval);
      }

      if (loadingTimer) {
        clearTimeout(loadingTimer);
      }
    };
  }, []);

  const goNext = () => {
    history.push("/start");
  };

  return (
    <Layout p={4} justify="space-between">
      <Box m={-4} mb={8}>
        <Progress value={value} height="4px" borderRadius="0px" mb={2} />
      </Box>

      <Flex my={6} direction="column" wrap={"wrap"} color="primary.500">
        <Box as={FaQuoteLeft} color="gray.400" />
        <Text fontFamily="heading" px={6} textAlign="center" mb={0} mt={-2}>
          When something is important enough, you do it even if the odds are not
          in your favour.
        </Text>
        <Box as={FaQuoteRight} color="gray.400" ml="auto" mt={-4} />
      </Flex>

      <Box my={4} mb={8}>
        <Box textAlign="center" mx={-4} mb={8}>
          <Image src="/images/one1.png" />
        </Box>

        <Text fontSize="sm" mb={6} color="gray.400" textAlign="center">
          We are determined to help you achieve your dream to be the number 1.
          Come let’s make them a reality.
        </Text>

        <Button colorScheme="primary" width="100%" onClick={goNext}>
          Start
        </Button>
      </Box>

      {/* <Box width="100%">
        <motion.div
          animate={{
            y: ["1000px", "0px"],
            opacity: ["0", "1"],
          }}
          transition={{
            type: "tween",
            duration: 2,
          }}
        >
          <Image src={logo} mx="auto" mb={10} />
        </motion.div>
        <motion.div
          animate={{
            y: ["1000px", "0px"],
            opacity: ["0", "1"],
          }}
          transition={{
            type: "tween",
            duration: 2,
            delay: 2,
          }}
        ></motion.div>

        <motion.div
          animate={{
            y: ["1000px", "0px"],
            opacity: ["0", "1"],
          }}
          transition={{
            type: "tween",
            duration: 2,
            delay: 2,
          }}
        >
          <Text fontSize="sm" color="gray.400" textAlign="center">
            {showBtn
              ? "Data fetched successfully, Please click start to continue"
              : " Fetching required data from the server, please wait"}
          </Text>
        </motion.div>

        <Box p={4} mt={6}>
          {showBtn ? (
            <motion.div
              animate={{
                opacity: ["0", "1"],
              }}
              transition={{
                type: "tween",
                duration: 1,
                delay: 0,
              }}
            ></motion.div>
          ) : (
            ""
          )}
        </Box>
      </Box> */}
    </Layout>
  );
};

export default LoadingScreen;
