import React, { useState, useEffect, useRef } from "react";
import Layout from "../components/layout";
import { Image, Box, Text, Flex, Button } from "@chakra-ui/react";
import CeoSelectImg from "../assets/ceo-select.png";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import ReactPlayer from "react-player";
import videojs from "video.js";
import "video.js/dist/video-js.css";

const StartScreen = () => {
  const history = useHistory();
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsPlaying(true);
    }, 4500);
  }, []);

  const goNext = () => {
    history.push("/select");
  };

  const mainBox = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
  };

  return (
    <Layout align="center" justify="center">
      <Box textAlign="center" p={4} pt={0}>
        <motion.div variants={mainBox} initial="hidden" animate="show">
          <motion.div
            animate={{
              y: ["1000px", "0px"],
              opacity: ["0", "1"],
            }}
            transition={{
              type: "tween",
              delay: 0.5,
              duration: 2,
            }}
          >
            <Box mx="auto" textAlign="center" m={-4}>
              <ReactPlayer
                loop={true}
                playing={isPlaying}
                controls={true}
                url="/video/intro.mp4"
                width="340px"
                height="200px"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </Box>
          </motion.div>
          <motion.div
            animate={{
              x: ["-1000px", "0px"],
              opacity: ["0", "1"],
            }}
            transition={{
              type: "tween",
              delay: 2.5,
              duration: 1,
            }}
          >
            <Text
              mt={4}
              color="primary.700"
              fontSize="2xl"
              fontWeight="bold"
              mb={2}
            >
              Hi Vikas,
            </Text>
          </motion.div>
          <motion.div
            animate={{
              x: ["1000px", "0px"],
              opacity: ["0", "1"],
            }}
            transition={{
              type: "tween",
              duration: 2.5,
              delay: 3.5,
            }}
          >
            <Text fontSize="sm" mb={6} color="primary.500">
              Welcome to
              <span
                style={{
                  color: "var(--chakra-colors-secondary-500)",
                  fontWeight: "bold",
                }}
              >
                &nbsp; My Success MileStones Calculator
              </span>
              , We are determined to help you achieve your dreams! Come let’s
              make them a reality.
            </Text>
          </motion.div>

          <motion.div
            animate={{
              y: ["1000px", "0px"],
              opacity: ["0", "1"],
            }}
            transition={{
              type: "tween",
              duration: 1,
              delay: 6.5,
            }}
          >
            <SelectLanguage />
          </motion.div>

          <motion.div
            animate={{
              y: ["1000px", "0px"],
              opacity: ["0", "1"],
            }}
            transition={{
              type: "tween",
              duration: 1,
              delay: 7.5,
            }}
          >
            <Button
              mt={4}
              colorScheme="secondary"
              width="100%"
              onClick={goNext}
            >
              Next
            </Button>
            <Text fontSize="xs" mt={2} color="gray.400">
              By Using this App you agree to the Terms & Conditions and Privacy
              policy.
            </Text>
          </motion.div>
        </motion.div>
      </Box>
    </Layout>
  );
};

const StartScreen2 = () => {
  const history = useHistory();

  const goNext = () => {
    history.push("/select");
  };

  return (
    <Layout align="center" justify="space-between">
      <VideoScreen />

      <Box my={4} p={4} mb={0} textAlign="center">
        <Text fontSize="md" color="primary.500">
          Welcome to
          <span
            style={{
              color: "var(--chakra-colors-secondary-500)",
              fontWeight: "bold",
            }}
          >
            &nbsp; My Success MileStones Calculator
          </span>
          , We are determined to help you achieve your dreams! Come let’s make
          them a reality.
        </Text>
      </Box>

      <SelectLanguage />

      <Box mx={6} mb={8} mt={4}>
        <Button mt={4} colorScheme="secondary" width="100%" onClick={goNext}>
          Next
        </Button>
        <Text fontSize="xs" mt={2} color="gray.400" textAlign="center">
          By Using this App you agree to the Terms & Conditions and Privacy
          policy.
        </Text>
      </Box>
    </Layout>
  );
};

//
const VideoScreen = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    loop: true,
    sources: [
      {
        src: "/video/intro-video.m4v",
        type: "video/mp4",
      },
    ],
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const player = videojs(videoElement, videoJsOptions, () => {
      console.log("player");
    });

    return () => {
      if (playerRef.current) {
        //@ts-ignore
        playerRef.current?.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <Box width="100%">
      <div data-vjs-player>
        <video ref={videoRef} className="video-js vjs-big-play-centered" />
      </div>
    </Box>
  );
};

export default StartScreen2;

const SelectLanguage = () => {
  const [lang, setLang] = useState("english");

  return (
    <Box mb={4} width="100%" p={4}>
      <Text textAlign="center" fontSize="sm" color="gray.400" mb={1}>
        Select Language
      </Text>

      <Flex direction="row">
        <Button
          width="100%"
          borderTopRightRadius="0"
          borderBottomRightRadius="0"
          fontWeight="normal"
          colorScheme={lang == "hindi" ? "primary" : "gray"}
          onClick={() => setLang("hindi")}
          _focus={{ boxShadow: "none", outline: "0" }}
          size="sm"
        >
          हिन्दी
        </Button>
        <Button
          width="100%"
          borderRadius="0"
          fontWeight="normal"
          colorScheme={lang == "english" ? "primary" : "gray"}
          onClick={() => setLang("english")}
          _focus={{ boxShadow: "none", outline: "0" }}
          size="sm"
        >
          English
        </Button>
        <Button
          width="100%"
          fontWeight="normal"
          borderTopLeftRadius="0"
          borderBottomLeftRadius="0"
          colorScheme={lang == "marathi" ? "primary" : "gray"}
          onClick={() => setLang("marathi")}
          _focus={{ boxShadow: "none", outline: "0" }}
          size="sm"
        >
          मराठी
        </Button>
      </Flex>
    </Box>
  );
};
