import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import StartScreen from "./screens/start";
import SelectScreen from "./screens/select";
import TabsScreen from "./screens/tabs";
import LoadingScreen from "./screens/loading";

const Navigation = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <LoadingScreen />
        </Route>
        <Route path="/start">
          <StartScreen />
        </Route>
        <Route path="/select">
          <SelectScreen />
        </Route>
        <Route path="/tabs">
          <TabsScreen />
        </Route>
      </Switch>
    </Router>
  );
};

export default Navigation;
